<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-22 17:07:43
 * @LastEditTime: 2023-12-04 10:45:35
 * @FilePath: \awx-ui\src\components\customDatePicker\index.vue
-->
<template>
  <div>
    <a-popover v-model="visible" placement="bottomLeft" overlayClassName="custom-date" trigger="click">
      <template #content>
        <div class="custom-time">
          <div v-if="setting.showMinute" class="seconds">
            <div>分钟</div>
            <div :class="['select-time', currentSearchDateText === '最近5分钟' && 'selected']" @click="countTime(5, 'minutes', '最近5分钟')">最近5分钟</div>
            <div :class="['select-time', currentSearchDateText === '最近10分钟' && 'selected']" @click="countTime(10, 'minutes', '最近10分钟')">最近10分钟</div>
            <div :class="['select-time', currentSearchDateText === '最近15分钟' && 'selected']" @click="countTime(15, 'minutes', '最近15分钟')">最近15分钟</div>
          </div>
          <div class="hour">
            <div>时</div>
            <div v-if="!setting.isDashBoard"  :class="['select-time', currentSearchDateText === '最近半小时' && 'selected']" @click="countTime(30, 'minutes', '最近半小时')">最近半小时</div>
            <div v-if="!setting.isDashBoard" :class="['select-time', currentSearchDateText === '最近1小时' && 'selected']" @click="countTime(1, 'hours', '最近1小时')">最近1小时</div>
            <div :class="['select-time', currentSearchDateText === '最近6小时' && 'selected']" @click="countTime(6, 'hours', '最近6小时')">最近6小时</div>
            <div v-if="setting.isDashBoard" :class="['select-time']" style="height: 29px"></div>
            <div v-if="setting.isDashBoard" :class="['select-time']" style="height: 29px"></div>
          </div>
          <div class="day">
            <div>天</div>
            <div :class="['select-time', currentSearchDateText === '半天' && 'selected']" @click="countTime(12, 'hours', '半天')">半天</div>
            <div :class="['select-time', currentSearchDateText === '一天' && 'selected']" @click="countTime(24, 'hours', '一天')">一天</div>
            <div :class="['select-time', currentSearchDateText === '近三天' && 'selected']" @click="countTime(2, 'day', '近三天')">近三天</div>
          </div>
          <div class="week">
            <div>周</div>
            <div :class="['select-time', currentSearchDateText === '本周' && 'selected']" @click="countTime(1, 'week', '本周')">本周</div>
            <div :class="['select-time', currentSearchDateText === '近两周' && 'selected']" @click="countTime(2, 'week', '近两周')">近两周</div>
            <div :class="['select-time', currentSearchDateText === '近三周' && 'selected']" @click="countTime(3, 'week', '近三周')">近三周</div>
          </div>
          <div class="month">
            <div>月</div>
            <div :class="['select-time', currentSearchDateText === '本月' && 'selected']" @click="countTime(1, 'month', '本月')">本月</div>
            <div v-if="setting.showTwoMonth" :class="['select-time', currentSearchDateText === '近两个月' && 'selected']" @click="countTime(2, 'month', '近两个月')">近两个月</div>
            <div v-else :class="['select-time']" style="height: 29px"></div>
            <div v-if="setting.showThreeMonth" :class="['select-time', currentSearchDateText === '近三个月' && 'selected']" @click="countTime(3, 'month', '近三个月')">近三个月</div>
            <div v-else :class="['select-time']" style="height: 29px"></div>
          </div>
        </div>
        <div class="custom-picker">
          <div>自定义</div>
          <a-range-picker class="range-picker" :showTime="{format: setting.timeForMat}" v-model="date" :allowClear="setting.allowClear" :format="setting.dateFormat" @change="changeDate" />
          <div class="date-piceker-icon">
            <svg-icon :icon-class="'custom-date'" />
          </div>
        </div>
        <div class="custom-date-footer">
          <div class="mgr12" @click="cancel">取消</div>
          <div @click="confirm">确定</div>
        </div>
      </template>
      <a-button :class="[date.length > 1 && date[0] && date[1] ? '' : 'custom-date-holder', 'custom-date-btn']">
        <div :class="[date.length > 1 && date[0] && date[1] ? '' : 'place-holder']">{{ dateText }}</div>
        <div class="date-icon">
          <svg-icon :icon-class="'custom-date'" />
        </div>
        <div class="clear-icon" v-if="setting.clearDate" @click.stop="clearDate">
          <svg-icon :icon-class="'clear'" />
        </div>
      </a-button>
    </a-popover>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    currentSearchDate: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentSearchDateText: {
      type: String,
      default: ''
    },
    setting: {
      type: Object,
      default: () => {
        return {
          isDashBoard: false,
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
          timeForMat: 'HH:mm:ss',
          showMinute: true,
          showTwoMonth: true,
          showThreeMonth: true,
          allowClear: false,
          clearDate: true,
          disabledDate: false
        }
      }
    }
  },
  data() {
    return {
      visible: false,
      date: null,
      dateText: '',
      changeFlag: false,
      offsetDays: 86400000 * 29,
      changeNum: null,
      changeType: ''
    };
  },
  watch: {
    currentSearchDate: {
      handler (val) {
        this.date = val
        this.dateText = this.currentSearchDateText
      },
      immediate: true
    },
    visible: {
      handler (val) {
        if (val) {
          this.date = this.currentSearchDate
          this.dateText = this.currentSearchDateText
        }
      },
    }
  },
  computed: {
  },
  components: {},
  mounted() {},
  methods: {
    countTime (num, type, text) {
      this.changeFlag = false
      this.changeNum = num
      this.changeType = type
      const currentDate = moment();
      // 计算最近两周的起始日期（往前推14天）
      const startDate = currentDate.clone().subtract(num, type).format('YYYY-MM-DD HH:mm:ss');
      // 计算最近两周的结束日期
      const endDate = currentDate.clone().format('YYYY-MM-DD HH:mm:ss');
      this.date = [startDate, endDate]
      this.cancel()
      this.dateText = text
      this.$emit('getType', type)
      this.$emit('getTime', this.date, text)
    },
    cancel () {
      this.visible = false;
    },
    clearDate () {
      this.visible = false;
      this.changeNum = null
      this.dateText = ''
      this.date = [null, null]
      this.$emit('getTime', [], '请选择时间')
    },
    confirm () {
      this.getCurrentTime()
      this.cancel()
    },
    getCurrentTime () {
      if (this.changeFlag) {
        this.dateText = this.date.join('~')
        this.$emit('getType', 'day')
        this.$emit('getTime', this.date, this.dateText)
      } else {
        if (!this.changeNum || !this.changeType) return this.$emit('getTime', this.date, this.dateText, 'empty')
        const currentDate = moment();
        // 计算最近两周的起始日期（往前推14天）
        const startDate = currentDate.clone().subtract(this.changeNum, this.changeType).format('YYYY-MM-DD HH:mm:ss');
        // 计算最近两周的结束日期
        const endDate = currentDate.clone().format('YYYY-MM-DD HH:mm:ss');
        this.date = [startDate, endDate]
        this.$emit('getType', 'day')
        this.$emit('getTime', this.date, this.dateText)
      }
    },
    changeDate (date, dateString) {
      const startDate = moment(date[0]);
      const endDate = moment(date[1]);
      const diffInDays = endDate.diff(startDate, 'days');
      if (diffInDays > 30 && this.setting.disabledDate) {
       this.date = [null, null]
       return this.$message.error('开始时间和结束时间的日期差不能超过一个月');
      }
      this.changeFlag = true
      this.date = dateString
    }
  },
};
</script>
<style lang='less'>
// 自定义时间选择器
.custom-date-btn {
  position: relative;
  min-width: 256px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cfd5de;
  padding-right: 56px;
  .date-icon {
    margin-left: 20px;
    position: absolute;
    top: 4px;
    right: 15px;
  }
  .clear-icon {
    display: none;
    margin-left: 20px;
    position: absolute;
    top: 4px;
    right: 36px;
    svg {
      color: rgb(191, 191, 191);
    }
    &:hover {
      svg {
        color: rgb(156, 156, 156);
      }
    }
  }
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    .clear-icon {
      display: block;
    }
  }
}
.custom-date-holder {
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    .date-icon {
      right: 15px;
    }
    .clear-icon {
      display: none;
    }
  }
}
.custom-date {
  //  z-index: 10000;
   margin-top: -10px;
   padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .custom-time {
    padding: 14px 20px 0;
    .seconds, .hour, .day, .week, .month {
      display: flex;
      align-items: center;
      cursor: pointer;
      div:first-child {
        margin-right: 10px;
        min-width: 42px;
        color: #757984;
      }
      .select-time {
        margin-right: 24px;
        min-width: 120px;
        color: #1D202D;
        padding: 4px 24px;
        border-left:1px solid #ECF0F4;
        &:hover {
          color: #3974F4;
        }
      }
      .selected {
        color: #3974F4;
      }
    }
  }
  .custom-picker {
    padding: 0 20px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    div:first-child {
      color: #757984;
      margin-right: 10px;
    }
    .range-picker {
      width: 430px!important;
    }
    .date-piceker-icon {
      position: absolute;
      right: 40px;
    }
  }
  .custom-date-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-top: 1px solid #ECF0F4;
    div {
      width: 62px;
      height: 28px;
      border-radius: 4px;
      background: #3974F4;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 28px;
      font-weight: 500;
      cursor: pointer;
    }
    div:first-child {
      background: #ECF1F7;
      color: #5A5A69;
    }
  }
}
.place-holder {
  color: #bfbfbf;
}
</style>